import React from 'react';
import './App.css';
import Projects from './project'
import head from './image/85895966.png';
import offic from './image/112-1127587_software-hire-developers-hd-png-download.png';

function App() {
  return (
    <div className="App">
      <div className="hero">
    <header className="App-header">
    <div className="title">Philip.</div>
    </header>
    <div className='content-home-center'>
    <div className="content-home">
    <div className='text-content'>
    <div className='icon-bar'>
    <div><a className='icon' href="https://github.com/philiphoney"><i className="bi bi-github"></i></a></div>
    <div><a className='icon' href="https://www.linkedin.com/in/philip-st%C3%BCssel-108a76261/"><i className="bi bi-linkedin"></i></a></div>
    <div><a className='icon' href="mailto:philip.stuessel@icloud.com"><i className="bi bi-envelope-at-fill"></i></a></div>
    </div>
    <div className='home-title'>Hello, I'm<br/><span className='home-title-bottom'>Philip Stüssel</span><br /><span className='sd'>Scroll down <i className="bi bi-arrow-down-short"></i></span></div> </div>
    <div className='home-image'><img src={head} alt="" /></div>
    </div></div></div>
    {/* <div className="about">
    <p>Welcome to my portfolio website! As a passionate web designer and backend developer, I present here a selection of my projects that reflect my skills and creativity. In addition to my freelance work, I am also proud to be part of the innovative team at <a target='_blank' rel="noopener noreferrer" href="https://gmf-design.de">GMF</a>, where I expand my skills and master new challenges every day. Each project below showcases my expertise in designing user-friendly, aesthetic and functional web solutions. Immerse yourself in my world of design and technology.</p>
    </div> */}
    <div className='projects'>
    <div className='content-banner-center'>
    <div className='banner-projects'>
    <div className='projects-title-content'>Projects</div>
    <div className='projects-image'><img src={offic} alt="" /></div>
    <div className='all-projects'>
    </div></div></div>
    <Projects />
     </div>
    <footer>
      <div className="footer-text">
      <div className='icon-main'>
      <div><a className='icon' href="https://github.com/philiphoney"><i className="bi bi-github"></i></a></div>
      <div><a className='icon' href="https://www.linkedin.com/in/philip-st%C3%BCssel-108a76261/"><i className="bi bi-linkedin"></i></a></div>
      <div><a className='icon' href="mailto:philip.stuessel@icloud.com"><i className="bi bi-envelope-at-fill"></i></a></div>
      </div>
      <div className='cft'>© 2024-present Philip Stuessel. All Rights Reserved. <br />
      <div>Picture of <a href="https://www.pngitem.com/middle/Jxihwo_software-hire-developers-hd-png-download/">&nbsp;Autumn Moultry</a>&nbsp;and&nbsp;<a href="https://gifs.eco.br/gifs-de-auditoria/"> gifs.eco.br</a></div></div>
      </div>
      <div className='footer-image'><img src="https://gifs.eco.br/wp-content/uploads/2022/07/gifs-de-auditoria-23.gif" alt="" /></div>
    </footer>
    </div>
  );
}

export default App;
